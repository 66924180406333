<template>
  <v-col :cols="12" class="pa-2">
    <h2 class="black--text text-right my-2">{{ label }}</h2>
  </v-col>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
